.app__wrapper{
    margin-top: -1.5rem;
    
    @media screen and (min-width:1500px) {
        width: 80%!important;
    }
}
.app__workinfo-title{
    margin-bottom: 0.5rem;
    margin-top: 1.5rem;
    hr{
        background: black;
        height: 3px;
        width: 90%;
    }

    .head-text{
    text-align:start;
    }
}
.app__workinfo_button{
    padding: 0.5rem 1.5rem;
    border-radius: 0.3rem;
    background-color: var(--secondary-color);
    color: #fff!important;
    font-weight: 800;
    cursor: pointer;
    margin-bottom: 1.5rem;
    margin-top: 1rem;
    width: fit-content;

    @media screen and (min-width:2000px) {
        padding: 1rem 2rem;
        border-radius: 0.85rem;
    }

}

.app__workinfo-resources{
    a{
        text-transform: uppercase;
    }
}

.app__workinfo-slider{
    margin-top: 30px;
    width: 100%;
    margin-bottom: -10px;
    .awssld__controls button {
        align-items:center;
        top: 0px;
        height: 100%;
        width: 50px;
        z-index: 1;
    }
    .awssld__controls button:hover  {
        box-shadow: 0px 0px 55px 55px black;
        background-color: black;
        opacity: 0.5;
    }

}
#work_info{
    .p-text2{
        text-align: justify;
    }
}